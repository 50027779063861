<template>
  <div></div>
</template>

<script>
import {getAction} from '@/api/manage';

export default {
  name: "unionLogin",
  data() {
    return {
      userKey: this.$route.query.user_key
    }
  },
  created() {
    this.getToken()
  },
  methods: {
    async getToken(){
      const res = await getAction('/jeecg-system/sys/loginByUserId', {
        userId: this.userKey
      })
      if(res.success){
        // 供应商 本地地址
        // http://localhost:8087/user/unionLogin?user_key=7669279587c26376729210b6162393b611040e054d613b0fdac5dc3d3df3c1d0
        // http://localhost:8087/user/unionLogin?user_key=7669279587c26376729210b6162393b692b0b0af379be5a4d6c178680a8b4642
        // 验货员 本地地址
        // http://localhost:8087/user/unionLogin?user_key=7669279587c26376729210b6162393b692b0b0af379be5a4d6c178680a8b4642
    // this.$store.commit("setnowlanguage", { command });
        let user=res.result.userInfo.roleCodes[0]
        sessionStorage.userRoleCodes=user
        // this.$store.commit('setUserRoleCodes',user)
        console.log(res.result.token)
        console.log('l:', this.$router.resolve(`/?token=${res.result.token}`))
        window.location = this.$router.resolve(`/?token=${res.result.token}`).href;
      }else this.Toast.fail(res.message)
    }
  },
}
</script>

<style scoped>

</style>
